import React, { memo } from 'react';
import _ from './windowButtonsHeader.module.css';

const WindowButtonsHeader = ({ buttons }) => {
  return (
    <div className={_.headerBtnWrapper}>
      {buttons && buttons.length > 0 && buttons.map(button => (
        <div key={button.name} onClick={button.action} className={_.headerBtn}>{button.name}</div>
      ))}
    </div>
  );
};

export default memo(WindowButtonsHeader);