import React, { useEffect, useState } from 'react'
import { format } from 'date-fns'

import _ from './clock.module.css'

const Clock = () => {
  const [time, setTime] = useState(format(new Date(), "HH:mm:ss"))

  useEffect(() => {
    setInterval(() => { setTime(format(new Date(), "HH:mm:ss")) }, 1000)
  }, [])

  return (
    <div className={_.clockWrapper}>
      <div className={_.time}>{time}</div>
      <div className={_.date}>{format(new Date(), "dd.LL.y")}</div>
    </div>
  )
}

export default Clock