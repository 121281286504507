import React, { useEffect, useRef, memo } from 'react'
import {AiFillCloseCircle} from 'react-icons/ai'
import clsx from 'clsx'
import _ from './icon.module.css'


const Icon = ({ name, Pic, selected, setSelected, start }) => {
  const icon = useRef();

    useEffect(() => {
      const eventStart = () => start()

      if (icon && icon.current) {
        icon.current.addEventListener('dblclick', eventStart, false)
          return () => {
            icon && icon.current && icon.current.removeEventListener('dblclick', eventStart, false) // eslint-disable-line
          };
      }
  }, [start]) // eslint-disable-line


  return (
    <div style={{ zIndex: 100 }}>
      {(selected === name) && <div className={_.unSelect} onClick={() => setSelected(null)} />}

      <div ref={icon} className={clsx(_.icon, selected === name && _.selected)} onClick={() => setSelected(name)}>
        <Pic className={_.iconImage} />
        <div className={_.whiteText}>{name}</div>
      </div>
    </div>
  )
}

export const FileIcon = ({ name, Pic, start, delAction }) => (
  <div style={{ zIndex: 100 }}>
    <div className={clsx(_.icon, _.fileIcon)} onClick={start}>
      {delAction && <div onClick={(e) => { e.stopPropagation(); delAction() }} className={_.removeContainer}><AiFillCloseCircle /></div>}
      <Pic className={_.iconImage} />
      <div className={_.iconTitle}>{name}</div>
    </div>
  </div>
)

export default memo(Icon);
