import React, { useRef, useContext, useState, useEffect } from 'react'
import clsx from 'clsx'
import ReactDOM from 'react-dom'

import _ from './modal.module.css'

export const ModalContext = React.createContext({ modalNode: null, setZIndexHack: _ => _ })

export function ModalProvider({ children }) {
    const modalRef = useRef()
    const [context, setContext] = useState()
    const [zIndexHack, setZIndexHack] = useState(false)

    useEffect(() => {
        setContext({ modalNode: modalRef.current, setZIndexHack })
    }, [])

    return (
        <div className={clsx(_.container, zIndexHack && _.zIndexHack)}>
            <ModalContext.Provider value={context}>{children}</ModalContext.Provider>
            <div ref={modalRef} />
        </div>
    )
}

export function Modal({ onClose, children, style = {}, ...props }) {
    const {modalNode} = useContext(ModalContext)

    return modalNode
        ? ReactDOM.createPortal(
            <div className={_.overlay} onClick={onClose}>
                <div style={style} onClick={(e) => e.stopPropagation()} className={_.dialog} {...props}>
                    {children}
                </div>
            </div>,
        modalNode
        )
        : null
}

export default Modal