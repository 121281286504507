import React from 'react'
import './App.css'

import Desktop from 'components/desktop'
import { ModalProvider } from 'components/modal/modal'
import { AuthProvider } from 'components/login/auth-context'

const App = () => {

  return (
    <AuthProvider>
      <ModalProvider>
        <Desktop />
      </ModalProvider>
    </AuthProvider>
  )
}

export default App
