import React, { memo, useState } from 'react';

import pic from 'assets/login_background.jpg'
import _ from "./login.module.css"

const defaultUsers = [
  { login: 'user1', pass: 'qwerty' },
  { login: 'user2', pass: 'qwerty' },
]

const Login = ({ setLogged }) => {
  const [login, setLogin] = useState('')
  const [pass, setPass] = useState('')

  const checkData = () => {
    const auth = defaultUsers.find(user => (user.login === login && user.pass === pass))
    if (auth) {
      setLogged(login)
      localStorage.setItem('userData', JSON.stringify({ name: login }))
    }
  }

  return (
    <div className={_.loginPage}>
      <img className={_.desktopImage} src={pic} alt='Логинка' />

      <div className={_.formWrapper}>
        <div>
          <div>Логин</div>
          <input type='text' value={login} onChange={(e) => setLogin(e.target.value)} />
        </div>
        <div>
          <div>Пароль</div>
          <input type='password' value={pass} onChange={(e) => setPass(e.target.value)} />
        </div>
        <button className={_.button} onClick={checkData}>Войти</button>
      </div>
    </div>
  );
};

export default memo(Login);
