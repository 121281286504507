import React, { useCallback, useEffect, useState, memo, useContext } from 'react'
import RichTextEditor from 'react-rte';

import WindowHeader from 'components/window-header';
import WindowButtonsHeader from 'components/window-buttons-header'
import AuthContext from 'components/login/auth-context'
import OpenFileModal from 'components/open-file-modal'
import SaveNewFileModal from 'components/save-new-file'

import _ from './textEditor.module.css'


const TextEditor = ({ closeEditor }) => {
  const [fileName, setFileName] = useState('')
  const [savedFileName, setSavedFileName] = useState('')
  const [showModalSetFileName, setShowModalSetFileName] = useState(false)
  const [showModalGetFile, setShowModalGetFile] = useState(false)
  const [headerButtons, setHeaderButtons] = useState([])

  const [editorValue, setEditorValue] = useState(RichTextEditor.createValueFromString('', 'html'))
  const [filesState, setFilesState] = useState([]) // список файлов пользователя
  const [filesDataState, setFilesDataState] = useState([]) // набор данных с файлами

  const user = useContext(AuthContext)

  useEffect(() => {
    const _files = localStorage.getItem('textFilesList') || '{}'
    const files = JSON.parse(_files)
    setFilesDataState(files)
    setFilesState(files[user.name] || [])
  }, []) // eslint-disable-line

  // сохранение файлов в localStorage
  const saveFile = useCallback(async (fileName) => {
    let files = filesState

    const newFile = {
      name: fileName,
      file: editorValue.toString("html"),
    }

    if (!files.length) {
      files.push(newFile)
    } else {
      const oldFile = files.find(f => f.name === fileName)
      if (!oldFile) {
        files.push(newFile)
      } else {
        const oldFiles = files.filter(f => f.name !== fileName)
        files = [...oldFiles, newFile]
      }
    }

    let _filesDataState = filesDataState
    _filesDataState[user.name] = files
    // console.log('_filesDataState', _filesDataState);
    localStorage.setItem('textFilesList', JSON.stringify(_filesDataState))
    setFilesDataState(_filesDataState)
    setFilesState(files)
  }, [filesState, editorValue]) // eslint-disable-line

  const openFile = ({ file, name }) => {
    setEditorValue(RichTextEditor.createValueFromString(file, 'html'))
    setShowModalGetFile(false)
    setSavedFileName(name)
  }

  const onSave = () => {
    if (fileName) {
      setSavedFileName(fileName);
      setShowModalSetFileName(false);
      saveFile(fileName)
    }
  }

  const onSaveHeader = useCallback(() => {
    if (savedFileName) {
      return saveFile(savedFileName)
    }
    setShowModalSetFileName(true)
  }, [savedFileName, saveFile])

  const delFileAction = (file) => {
    let files = filesState
    const newUserFiles = files.filter(f => f.name !== file.name)

    let _filesDataState = filesDataState
    _filesDataState[user.name] = newUserFiles

    localStorage.setItem('textFilesList', JSON.stringify(_filesDataState))
    setFilesDataState(_filesDataState)
    setFilesState(newUserFiles)
  }

  useEffect(() => {
    const _headerButtons = [
      { name: 'Открыть файл', action: () => setShowModalGetFile(true) },
      { name: 'Сохранить', action: onSaveHeader },
      { name: 'Сохранить как', action: () => setShowModalSetFileName(true) },
    ]
    setHeaderButtons(_headerButtons)
  }, [onSaveHeader])

  return (<div className={_.bigWindow}>
    <WindowHeader name='Блокнот' close={closeEditor} addedName={savedFileName} />
    <WindowButtonsHeader buttons={headerButtons} />

    <RichTextEditor
      value={editorValue}
      onChange={setEditorValue}
      editorClassName={_.richTextEditor}
      multiline
    />

    <SaveNewFileModal
      show={showModalSetFileName}
      hide={() => setShowModalSetFileName(false)}
      fileName={fileName}
      onChange={(e) => setFileName(e.target.value)}
      onSave={onSave}
    />

    <OpenFileModal
      show={showModalGetFile}
      hide={() => setShowModalGetFile(false)}
      openFile={openFile}
      list={filesState}
      delAction={delFileAction}
    />
  </div>)
}

export default memo(TextEditor)