import React, { memo } from 'react'
import { AiOutlineFileText } from 'react-icons/ai'

import Modal from 'components/modal/modal'
import { AiOutlineFileAdd } from 'react-icons/ai'

import { FileIcon } from 'components/icon'

import _ from './open-file-modal.module.css'

const OpenFileModal = ({ show, hide, list, openFile, delAction, openNew }) => (
  <div>
    {show && <Modal onClose={hide}>
      <div>Выберите файл</div>
      <div className={_.filesWrapper}>
        {list && list.length > 0 && list.map(file =>
          <FileIcon key={file.name} start={() => openFile(file)} Pic={AiOutlineFileText} name={file.name} delAction={() => delAction(file) } />
        )}
        {(!list || list.length === 0) && <div className='italic'>*нет сохранённых файлов*</div>}
        {openNew && <FileIcon start={openNew} Pic={AiOutlineFileAdd} name='Открыть новый' />}
      </div>
    </Modal>}
  </div>
)

export default memo(OpenFileModal)