import React, { memo } from 'react'
import Modal from 'components/modal/modal'

const SaveNewFileModal = ({ show, hide, onSave, fileName, onChange }) => (
  <div>
    {show && <Modal onClose={hide}>
      <div>Введите имя файла</div>
      <input type='text' value={fileName} onChange={onChange} />
      <div onClick={onSave}>Сохранить</div>
    </Modal>}
  </div>
)

export default memo(SaveNewFileModal)