import React, { useState, createContext, useEffect } from 'react';
import Login from './login'

const AuthContext = createContext({ name: '', clearAuth: _ => _ });

export const AuthProvider = ({ children }) => {
  const [logged, setLogged] = useState('')

  useEffect(() => {
    const _user = localStorage.getItem('userData') || '{}'
    const user = JSON.parse(_user)
    if (user && user.name) setLogged(user.name)
  }, [])

  if (!logged) return (<Login setLogged={setLogged} />)

  return (
    <div>
      <AuthContext.Provider value={{name: logged, clearAuth: () => setLogged('')}}>{children}</AuthContext.Provider>
    </div>
  )
}

export default AuthContext