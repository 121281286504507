import React, { memo, useEffect, useState } from 'react'
import clsx from 'clsx'
import {Calculator} from 'react-mac-calculator'

import WindowHeader from 'components/window-header'
import _ from './calc.module.css'

const Calc = ({ closeCalc, hide }) => {
  const [animationDelayHide, setAnimationDelayHide] = useState(false)
  useEffect(() => {
    if (hide) setTimeout(() => { setAnimationDelayHide(false) }, 500)
    if (!hide) setAnimationDelayHide(true)
  }, [hide])
  
  return (
    <div className={clsx(_.calcWrapper, hide && _.hide)}>
      <WindowHeader name='Калькулятор' close={closeCalc} />
      {animationDelayHide && <Calculator />}
    </div>
  );
};

export default memo(Calc);