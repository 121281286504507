import React, { useState, useEffect, useContext, memo } from 'react';
import { FcCalculator, FcEditImage, FcFile, FcExport } from 'react-icons/fc'
import Painterro from 'painterro'

import AuthContext from 'components/login/auth-context'
import Icon from 'components/icon'
import Calc from 'components/calc'
import TextEditor from 'components/text-editor'
import Clock from 'components/clock';
import OpenFileModal from 'components/open-file-modal'
import SaveNewFileModal from 'components/save-new-file'
import { ModalContext } from 'components/modal/modal';


import pic from 'assets/desktop_background.jpg'
import _ from "./desktop.module.css"

//  function dataURLtoFile(dataurl, filename) {
 
//         var arr = dataurl.split(','),
//             mime = arr[0].match(/:(.*?);/)[1],
//             bstr = atob(arr[1]), 
//             n = bstr.length, 
//             u8arr = new Uint8Array(n);
            
//         while(n--){
//             u8arr[n] = bstr.charCodeAt(n);
//         }
        
//         return new File([u8arr], filename, {type:mime});
//     }

const Desktop = () => {
  const [selected, setSelected] = useState(null)
  const [showCalc, setShowCalc] = useState(false)
  const [showTextEditor, setShowTextEditor] = useState(false)

  const ModalData = useContext(ModalContext)

  const [showModalSetFileName, setShowModalSetFileName] = useState(false)
  const [fileName, setFileName] = useState('')
  const [showModalGetFile, setShowModalGetFile] = useState(false)
  const [filesState, setFilesState] = useState([]) // список файлов пользователя
  const [filesDataState, setFilesDataState] = useState([]) // набор данных с файлами
  const [savingFile, setSavingFile] = useState(null) // сохраняемый файл в base64
  const user = useContext(AuthContext)

  // монтирование Painterro после создания контекста модалок
  useEffect(() => {
    if (ModalData)
      window.ptro = Painterro({
        saveHandler: (image, done) => {
          const base64Pic = image.asDataURL()
          ModalData && ModalData.setZIndexHack(true)
          setShowModalSetFileName(true)
          setSavingFile(base64Pic)
          done(true)
        }
      })
  }, [JSON.stringify(ModalData && ModalData.setZIndexHack || '')]) // eslint-disable-line

  // сохранение файла в localStorage
  const saveFileToLocalStorage = (name, file) => {
    const fileToSave = { name, file }
    let files = filesState

    if (!files.length) {
      files.push(fileToSave)
    } else {
      const oldFile = files.find(f => f.name === fileName)
      if (!oldFile) {
        files.push(fileToSave)
      } else {
        const oldFiles = files.filter(f => f.name !== fileName)
        files = [...oldFiles, fileToSave]
      }
    }

    let _filesDataState = filesDataState
    _filesDataState[user.name] = files
    localStorage.setItem('imageFilesList', JSON.stringify(_filesDataState))
    setFilesDataState(_filesDataState)
    setFilesState(files)
  }

  // забираем список сохранённых файлов из localStorage
  useEffect(() => {
    const _files = localStorage.getItem('imageFilesList') || '{}'
    const files = JSON.parse(_files)
    setFilesDataState(files)
    setFilesState(files[user.name] || [])
  }, []) // eslint-disable-line

  // открытие сохранённой картинки
  const openFile = (data) => {
    window.ptro.show(data.file)
    setShowModalGetFile(false)
  }

  const onSave = () => {
    if (fileName) {
      ModalData && ModalData.setZIndexHack(false)
      saveFileToLocalStorage(fileName, savingFile)
      setShowModalSetFileName(false)
    }
  }

  // открытие пэинта
  const openPaint = (force = false) => {
    setShowCalc(false)
    if (force) {
      window.ptro.show()
      return setShowModalGetFile(false)
    }
    if (filesState.length) return setShowModalGetFile(true)
    window.ptro.show()
  }

  // удаление файла из сохранённых
  const delFileAction = (file) => {
    let files = filesState
    const newUserFiles = files.filter(f => f.name !== file.name)

    let _filesDataState = filesDataState
    _filesDataState[user.name] = newUserFiles

    localStorage.setItem('imageFilesList', JSON.stringify(_filesDataState))
    setFilesDataState(_filesDataState)
    setFilesState(newUserFiles)
  }

  return (
    <div className={_.desktop}>
      <img className={_.desktopImage} src={pic} alt='Рабочий стол' />

      <Calc closeCalc={() => setShowCalc(false)} hide={!showCalc} />
      {/* калькулятор перехватывает кнопки F**, ctrl, alt и цифры */}

      {showTextEditor && <TextEditor closeEditor={() => setShowTextEditor(false)} />}

      <Icon className={_.iconImage} name='Калькулятор' Pic={FcCalculator} setSelected={setSelected} selected={selected} start={() => setShowCalc(true)} />
      <Icon className={_.iconImage} name='Блокнот' Pic={FcFile} setSelected={setSelected} selected={selected} start={() => setShowTextEditor(true)} />
      <Icon className={_.iconImage} name='Пэинт' Pic={FcEditImage} setSelected={setSelected} selected={selected} start={openPaint} />
      {/* в Painterro можно подтыкать картинки в base64 */}

      <Icon className={_.iconImage} name='Выход' Pic={FcExport} setSelected={setSelected} selected={selected} start={user.clearAuth} />

      <OpenFileModal
        show={showModalGetFile}
        hide={() => setShowModalGetFile(false)}
        openFile={openFile}
        list={filesState}
        delAction={delFileAction}
        openNew={() => openPaint(true)}
      />

      <SaveNewFileModal
        show={showModalSetFileName}
        hide={() => setShowModalSetFileName(false)}
        fileName={fileName}
        onChange={(e) => setFileName(e.target.value)}
        onSave={onSave}
      />

      <Clock />
    </div>
  );
};

export default memo(Desktop);
