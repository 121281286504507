import React, { memo } from 'react';
import { AiOutlineClose } from 'react-icons/ai'
import _ from './windowHeader.module.css';

const WindowHeader = ({ name, close, addedName }) => {
  return (
    <div className={_.windowHeader}>
      {name}{ addedName ? ` - ${addedName}` : '' }
      <div className={_.closeBtn} onClick={close}><AiOutlineClose /></div>
    </div>
  );
};

export default memo(WindowHeader);